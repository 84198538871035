body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans" !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

body::-webkit-scrollbar {
    display: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

select {
    font-family: "Open Sans" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

.spin {
    display: block;
    margin: auto;
    text-align: center;
}

.spin span {
    display: block;
    text-align: center;
    margin: auto;
    width: 32px;
    height: 32px;
    border: 2px dashed #000;
    border-radius: 50px;
}

.spin span {
    display: block;
    text-align: center;
    margin: auto;
    width: 32px;
    height: 32px;
    border: 2px solid #fecc08;
    border-bottom: 2px solid #04105C;
    border-radius: 50px;
    animation: 1.25s linear infinite spinner;
    animation-play-state: inherit;
}

.spin {
    height: calc(100%);
    overflow: hidden;
}

.swal2-container {
    background: #04105C;
    width: auto;
    height: auto;
}

.swal2-popup {
    background: #04105C !important;
}

.swal2-title {
    background: #04105C;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.5px;
    color: #ffffff !important;
    border-radius: 5px 5px 0px 0px;
}

.swal2-title-warning {
    background: #04105C;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.5px;
    color: #fecc08 !important;
    text-transform: uppercase !important;
}

.swal2-html-container {
    background: #04105C;
}

.swal2-html-container-warning span {
    display: block;
    text-align: center;
    margin: auto;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.5px;
    color: #ffffff;
    height: calc(100%);
    overflow: hidden;
}

.swal2-html-container-warning span i {
    display: block;
    margin: 10px auto 20px;
    text-align: center;
    font-size: 64px;
}

@keyframes spinner {
    0% {
        transform: translate3d(0%, 0%, 0) rotate(0deg);
        overflow: hidden !important;
    }
    100% {
        transform: translate3d(0%, 0%, 0) rotate(360deg);
        overflow: hidden !important;
    }
}