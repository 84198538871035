body:has(.overlay-shadow.active) {
    height: calc(100%);
    overflow: hidden;
}

.overlay-shadow {
    height: 100%;
    position: fixed;
    z-index: 0;
    top: 0;
    right: 0;
    background: #ffffff;
    height: calc(100%);
    overflow: hidden;
    width: 100%;
    transition: 0.4s;
}

.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background: #04105c;
    height: calc(100%);
    overflow: hidden;
    transition: 0.4s;
    border-radius: 0px 0px 0px 195px;
}

.overlay-content {
    position: relative;
    top: 59px;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.overlay a {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #ffffff;
    display: block;
    transition: 0.5s;
    text-decoration: none;
    padding: 47px 0px;
    border-bottom: 1px solid #d1a219;
    margin: 5%;
    text-align: right;
}

.overlay .overlay-bottom {
    position: absolute;
    top: 85%;
    width: 100%;
}

.overlay-bottom img {
    display: block;
    text-align: center;
    margin: auto;
}

.overlay a:hover,
.overlay a:focus {
    color: #f1f1f1;
}

.overlay .closebtn {
    position: absolute !important;
    top: 0%;
    padding: 0%;
    margin: 0%;
    border: hidden;
}

.closebtn i {
    vertical-align: super;
}

.header {
    position: relative;
    width: auto;
    height: auto;
    background: #04105c;
    border-radius: 0px 0px 200px 0px;
    padding-bottom: 0.1%;
}

.navbar-brand {
    margin: 0% 5% !important;
}

.navbar-brand-title {
    display: block;
    margin: auto;
    text-align: left;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #ffffff;
}

.nav-link {
    display: block;
    font-style: normal;
    font-size: var(--bs-navbar-brand-font-size);
    line-height: 30px;
    color: #ffffff;
    text-transform: uppercase;
}

.nav-link:hover {
    color: #d1a219 !important;
    opacity: 0.9;
}

.nav-link.active {
    color: #d1a219 !important;
}

.navbar-title {
    display: block;
    text-align: initial;
    font-family: "Open Sans" !important;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 80px;
    letter-spacing: 1.55172px !important;
    color: #ffffff;
    padding-left: 12.5%;
}

.navbar-title .first {
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    letter-spacing: 1.55172px;
    line-height: 80px;
}

.navbar-title .last {
    font-style: normal;
    font-weight: 700;
    font-size: 72px;
    letter-spacing: 1.55172px;
    line-height: 80px;
    padding-bottom: 1%;
}

.form-one-step-2 .title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 100%;
    line-height: 100%;
    color: #04105c;
}

.navbar-tel {
    display: block;
    margin: auto;
    text-align: right;
    padding-right: 15%;
}

.navbar-tel a {
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 2px;
    color: #ffffff;
    vertical-align: super;
}

.navbar-actions {
    padding: 75px 96px !important;
}

.navbar-line {
    display: block;
    margin: 0;
}

.navbar-line span {
    display: block;
    background: #d1a219;
    min-width: 10px !important;
    width: 50px !important;
    height: 2px;
    float: right;
    margin: 15% 0% !important;
}

.navbar-button {
    display: block;
    margin: auto;
    text-align: center;
    font-family: "Nunito Sans";
    background: #d1a219 !important;
    width: 90% !important;
    min-width: 180px;
    height: 45px;
    min-height: 45px;
    max-height: 55px !important;
    padding: 0px !important;
    border-radius: 0px !important;
    font-style: normal;
    font-weight: 700;
    font-size: 100%;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 66px !important;
}

.navbar .navbar-mobile {
    display: none;
}

.navbar-mobile button {
    border: none;
    display: block;
    padding: 0%;
    margin: 0%;
    text-align: center;
}

.card {
    margin: 0% 2.5%;
}

.card-body {
    background: #d1a219;
}

.card-img-top {
    background: #04105c !important;
}

.card-title {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ffffff;
}

.card-text {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #ffffff;
    text-decoration: none;
}

.select-title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #04105c;
}

.form-title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #04105c;
}

.form-subtitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: #606060;
}

.form-label {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #606060;
}

.form-title-billing {
    font-size: 3rem;
    color: #04105c;
}

.form-dialog {
    display: block;
    margin: auto;
    text-align: center;
    width: 100%;
    height: 100%;
    background: #04105c !important;
    padding: 10px 30px;
}

.form-dialog-title {
    display: block;
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: #d1a219;
}

.form-dialog-subtitle {
    display: block;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.8);
}

.form-dialog-icons {
    text-align: left;
    padding: 5px !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.5);
}

.btn-form {
    display: block;
    margin: auto;
    text-align: center;
    border-radius: 10px !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 22px;
    line-height: 34px;
    width: 100% !important;
    letter-spacing: 0.5px;
}

.btn-form:hover {
    color: #fff !important;
}

.btn-form-disabled {
    display: block;
    margin: auto;
    text-align: center;
    border-radius: 10px !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 22px;
    line-height: 34px;
    width: 100% !important;
    letter-spacing: 0.5px;
}

.btn-form-cancel {
    border: hidden !important;
    border-radius: 10px !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 22px;
    line-height: 34px;
    width: 100% !important;
    text-decoration: underline !important;
    letter-spacing: 0.5px;
}

.btn-form-cancel:hover {
    color: #ffffff !important;
}

.btn-pag-left {
    display: block;
    margin: auto;
    text-align: right;
}

.btn-pag-left i {
    color: #d1a219 !important;
    font-weight: 700;
    font-size: 10px;
}

.pag-count {
    color: #04105c !important;
    font-weight: 700;
    padding: 4.5px 0px;
    font-size: medium !important;
}

.btn-pag-right {
    display: block;
    margin: auto;
    text-align: left;
}

.btn-pag-right i {
    color: #d1a219 !important;
    font-size: 10px;
}

.footer {
    position: relative;
    width: auto;
    height: auto;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #04105c;
    padding-left: 10px;
}

.footer-title {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 35px;
    letter-spacing: 2px;
    color: #ffffff;
    padding-top: 100px;
    padding-bottom: 0px;
}

.footer-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #ffffff;
    vertical-align: super !important;
}

.footer-subtitle-segundo {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    /* identical to box height, or 188% */
    letter-spacing: 0.5px;
    color: #d1a219;
}

.footer-second {
    position: relative;
    width: auto;
    height: auto;
    background: #000730;
    padding: 1%;
    align-content: space-between;
    vertical-align: super;
}

.i-color {
    color: #d1a219 !important;
}

.mapa {
    width: 100%;
    height: 729px;
    padding: 5%;
}

@media screen and (width <=3440px) {
    .navbar-title .first {
        font-size: 72px;
        line-height: 120px;
        letter-spacing: 1.25px;
    }
    .nav-link {
        font-size: large;
    }
    .navbar-title .last {
        font-size: 98px;
        line-height: 140px;
    }
    .navbar-actions .navbar-line {
        margin: -1% 0% !important;
    }
}

@media screen and (height <=1440px) {
    .body-height {
        height: max-content;
        min-height: 520px;
    }
}

@media screen and (width <=1366px) {
    .navbar-brand {
        font-size: 15px;
    }
    .navbar-title {
        display: block;
        margin: auto;
    }
    .navbar-title .first {
        font-size: 56px;
        letter-spacing: 1.55172px;
        line-height: 80px;
    }
    .navbar-title .last {
        font-size: 78px;
        letter-spacing: 1.55172px;
        line-height: 80px;
        padding-bottom: 1%;
    }
    .navbar-actions .navbar-line {
        margin: 0% 0% !important;
    }
    .card-title {
        font-size: medium;
    }
    .card-text {
        font-size: 0.7rem;
    }
}

@media screen and (width <=1024px) {
    .nav-link {
        display: block;
        text-align: center;
        padding-left: 5%;
        font-size: x-small !important;
    }
    .navbar-brand {
        font-size: 15px !important;
    }
    .navbar-title {
        display: block;
        margin: auto;
    }
    .navbar-title .first {
        font-size: 35px;
        letter-spacing: 1.5px;
        line-height: 50px;
    }
    .navbar-title .last {
        font-size: 49px;
        letter-spacing: 2px;
        line-height: 50px;
        padding-bottom: 1%;
    }
    .navbar-actions {
        padding: 75px 96px !important;
    }
    .card-title {
        font-size: medium;
    }
    .card-text {
        font-size: 0.55rem;
    }
    .footer-second h4.footer-subtitle.hidden {
        display: none;
    }
}

@media screen and (height <=912px) {
    .navbar-line {
        margin: -1% 0% !important;
    }
}

@media screen and (width <=912px) {
    .nav-link {
        font-size: small !important;
    }
    .navbar-brand {
        font-size: 15px !important;
    }
    .navbar-title {
        display: block;
        margin: auto;
    }
    .navbar-title .first {
        font-size: 60%;
        letter-spacing: 1.5px;
        line-height: 50px;
    }
    .navbar-title .last {
        font-size: 82%;
        letter-spacing: 2px;
        line-height: 50px;
        padding-bottom: 1%;
    }
    .navbar-actions {
        padding: 75px 96px !important;
    }
    .navbar-line {
        margin: 2% 0% !important;
    }
    .card img {
        height: 100% !important;
    }
    .card-title {
        font-size: medium;
    }
    .card-text {
        font-size: 0.55rem;
        line-height: 100%;
    }
    .footer-second h4.footer-subtitle.hidden {
        display: none;
    }
}

@media screen and (height <=820px) {
    .navbar-title {
        display: block;
        margin: auto;
    }
    .navbar-title .first {
        font-size: 40px;
        letter-spacing: 100%;
        line-height: 40px;
    }
    .navbar-title .last {
        font-size: 55px;
        letter-spacing: 2px;
        line-height: 100%;
    }
    .nav-link {
        font-size: medium !important;
    }
    .navbar-line {
        margin: 2% 0% !important;
    }
    .card img {
        height: 100% !important;
    }
    .card-title {
        font-size: medium;
        line-height: 20px;
    }
    .card-text {
        font-size: 0.55rem;
        line-height: 100%;
    }
    .footer-second {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
        vertical-align: super !important;
    }
    .footer-second h4.footer-subtitle {
        font-size: 15px !important;
        letter-spacing: 0.5px !important;
    }
}

@media screen and (width <=820px) {
    .navbar-title {
        display: block;
        margin: auto;
    }
    .navbar-title .first {
        font-size: 27px;
        letter-spacing: 1.5px;
        line-height: 40px;
    }
    .navbar-title .last {
        font-size: 37px;
        letter-spacing: 2px;
        line-height: 40px;
    }
    .nav-link {
        font-size: 0.55rem !important;
    }
    form .hidden {
        display: none !important;
    }
    .form-one-step-1 .col-md-3,
    .form-one-step-2 .col-md-3,
    .form-one-step-2 .col-md-4 {
        width: 232px !important;
    }
    .form-two-step-1 .col-md-2,
    .form-two-step-1 .col-md-3,
    .form-two-step-1 .col-md-4,
    .form-two-step-1 .col-md-9 {
        width: 360px !important;
    }
    .card img {
        height: 100% !important;
    }
    .card-title {
        font-size: medium;
        line-height: 20px;
    }
    .card-text {
        font-size: 0.55rem;
        line-height: 100%;
    }
    .footer-second {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
        vertical-align: super !important;
    }
    .footer-second h4.footer-subtitle {
        font-size: 15px !important;
        letter-spacing: 0.5px !important;
    }
}

@media screen and (width <=768px) {
    .header {
        width: auto;
    }
    .nav-link {
        font-size: small !important;
    }
    .navbar-tel {
        display: none;
    }
    .navbar-title {
        display: block;
        margin: auto;
    }
    .navbar-title .first {
        font-size: 29px;
        letter-spacing: 100%;
        line-height: 40px;
    }
    .navbar-title .last {
        font-size: 40px;
        letter-spacing: 2px;
        line-height: 100%;
    }
    .navbar-button {
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
        align-self: center !important;
    }
    .card img {
        height: 100% !important;
    }
    .card-title {
        font-size: medium;
        line-height: 20px;
    }
    .card-text {
        font-size: 0.55rem;
        line-height: 100%;
    }
    .form-one-step-1 .col-12,
    .form-two-step-1 .col-12 {
        width: 50% !important;
    }
    .form-one-step-1 .col-md-3 {
        width: 33% !important;
    }
    .form-one-step-2 .col-12 {
        width: 33% !important;
    }
    .footer-second {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
        vertical-align: super !important;
    }
    .footer-second h4.footer-subtitle {
        font-size: 15px !important;
        letter-spacing: 0.5px !important;
    }
}

@media screen and (height <=768px) {
    .nav-link {
        font-size: small !important;
    }
    .navbar-title {
        display: block;
        margin: auto;
    }
    .navbar-title .first {
        font-size: 38px;
        letter-spacing: 100%;
        line-height: 40px;
    }
    .navbar-title .last {
        font-size: 52px;
        letter-spacing: 2px;
        line-height: 100%;
    }
    .navbar-button {
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
        align-self: center !important;
    }
    .card img {
        height: 100% !important;
    }
    .card-title {
        font-size: medium;
        line-height: 20px;
    }
    .card-text {
        font-size: 0.55rem;
        line-height: 100%;
    }
    .footer-second {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
        vertical-align: super !important;
    }
    .footer-second h4.footer-subtitle {
        font-size: 15px !important;
        letter-spacing: 0.5px !important;
    }
}

@media screen and (width <=540px) {
    .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 15px;
    }
    .header {
        border-radius: 0px 0px 65px 0px;
    }
    .nav-link {
        display: none !important;
    }
    .navbar .navbar-mobile {
        display: block;
        border: none;
    }
    .navbar-brand {
        display: block;
        margin: auto;
        text-align: center;
    }
    .navbar-brand img {
        display: block;
        margin: auto;
    }
    .navbar-brand .navbar-brand-title {
        display: none;
    }
    .navbar-title .first {
        font-size: 36.5px;
        line-height: 40px;
    }
    .navbar-title .last {
        font-size: 50px;
        line-height: 40px;
    }
    .navbar-line {
        display: none;
    }
    .navbar-actions button {
        display: block !important;
        margin: auto !important;
        text-align: center !important;
    }
    .navbar-img {
        display: none;
    }
    .card img {
        height: 100% !important;
    }
    .card-title {
        font-size: x-large;
        line-height: 100%;
    }
    .card-text {
        font-size: 0.75rem;
        text-align: center;
        line-height: 100%;
    }
    .form-one-step-1 .col-12,
    .form-two-step-1 .col-12 {
        width: 50% !important;
    }
    .form-one-step-1 .col-md-3 {
        width: 33% !important;
    }
    .form-one-step-2 .col-12 {
        width: 33% !important;
    }
    .footer-second {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
        vertical-align: super !important;
    }
    .footer {
        display: block;
        margin: auto;
        text-align: center;
    }
    .footer-second {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }
    .footer-second h4.footer-subtitle {
        font-size: small !important;
        letter-spacing: 0.5px !important;
    }
}

@media screen and (height <=540px) {
    .nav-link {
        font-size: 0.61rem !important;
    }
    .navbar-brand {
        display: block;
        margin: auto;
        text-align: center;
    }
    .navbar-brand img {
        display: block;
        margin: auto;
    }
    .navbar-brand .navbar-brand-title {
        display: none;
    }
    .navbar-title .first {
        font-size: 27px;
        line-height: 35px;
    }
    .navbar-title .last {
        font-size: 37px;
        line-height: 35px;
    }
    .navbar-tel {
        display: block !important;
    }
    .card img {
        height: 100% !important;
    }
    .card-title {
        font-size: x-large;
        line-height: 100%;
    }
    .card-text {
        font-size: 0.75rem;
        text-align: center;
        line-height: 100%;
    }
    .footer {
        display: block;
        margin: auto;
        text-align: center;
    }
    .footer-second {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }
    .footer-second h4.footer-subtitle {
        font-size: small !important;
        letter-spacing: 0.5px !important;
    }
}

@media screen and (width <=425px) {
    .overlay a {
        font-size: 20px;
    }
    .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 15px;
    }
    .navbar-line {
        display: none !important;
    }
    .navbar-brand {
        font-size: 15px !important;
    }
    .nav-link {
        display: none !important;
    }
    .navbar-title .first {
        font-size: 28px;
    }
    .navbar-title .last {
        font-size: 38px;
    }
    .navbar-actions button {
        display: block !important;
        margin: auto !important;
        text-align: center !important;
    }
    .card-text {
        font-size: x-small;
        line-height: 100%;
    }
    .form-one-step-1 .col-12,
    .form-one-step-2 .col-12,
    .form-two-step-1 .col-12 {
        width: 100% !important;
    }
    .footer {
        display: block;
        margin: auto;
        text-align: center;
    }
    .footer img {
        display: block;
        margin: auto;
        text-align: center;
    }
    .footer-second {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }
    .footer-second h4.footer-subtitle {
        font-size: 13.5px !important;
        letter-spacing: 0.5px !important;
    }
    .hidden-mobile {
        display: none;
    }
}

@media screen and (height <=425px) {
    .navbar-line {
        display: block !important;
        margin: 5% 0% !important;
    }
    .navbar-img {
        display: block !important;
    }
    .navbar-brand {
        font-size: 15px !important;
    }
    .nav-link {
        display: block !important;
        font-size: x-small !important;
    }
    .navbar-tel {
        display: block !important;
    }
    .navbar-title {
        display: block;
        margin: auto;
        padding-left: 12.5%;
    }
    .navbar-title .first {
        font-size: 28px;
    }
    .navbar-title .last {
        font-size: 38px;
    }
    .card img {
        height: 100%;
    }
    .card-title {
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #ffffff;
    }
    .card-text {
        font-size: 0.55rem;
        line-height: 10px;
    }
    .form-one-step-1 .col-12,
    .form-two-step-1 .col-12 {
        width: 50% !important;
    }
    .form-one-step-1 .col-md-3 {
        width: 33% !important;
    }
    .form-one-step-2 .col-12 {
        width: 33% !important;
    }
    .footer {
        display: block;
        margin: auto;
        text-align: center;
    }
    .footer img {
        display: block;
        margin: auto;
        text-align: center;
    }
    .footer-second {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }
    .footer-second h4.footer-subtitle {
        font-size: 13.5px !important;
        letter-spacing: 0.5px !important;
    }
}

@media screen and (height <=375px) {
    .navbar-line {
        display: block;
    }
    .nav-link {
        font-size: x-small !important;
    }
    .navbar-tel {
        display: none;
    }
    .card-text {
        font-size: x-small;
        line-height: 110%;
    }
    .form-one-step-1 .col-12,
    .form-two-step-1 .col-12 {
        width: 50% !important;
    }
    .form-one-step-1 .col-md-3 {
        width: 33% !important;
    }
    .form-one-step-2 .col-12 {
        width: 33% !important;
    }
    .footer-second {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
        vertical-align: super !important;
    }
    .footer {
        display: block;
        margin: auto;
        text-align: center;
    }
}

@media screen and (width <=375px) {
    .nav-link {
        display: none !important;
    }
    .navbar-brand img {
        display: block !important;
        margin: auto !important;
        text-align: center;
    }
    .navbar-brand {
        font-size: 15px !important;
    }
    .navbar-title .first {
        font-size: 23px;
        line-height: 40px;
    }
    .navbar-title .last {
        font-size: 32px;
        line-height: 40px;
    }
    .navbar-actions {
        padding: 76px 97px !important;
    }
    .navbar-button {
        position: absolute;
        left: 0%;
        right: 5%;
        top: 60%;
        bottom: 15%;
        display: block;
        margin: auto;
        text-align: center;
        max-width: 190px !important;
    }
    .card-text {
        line-height: 10px;
        font-size: x-small;
    }
    .footer {
        display: block;
        margin: auto;
        text-align: center;
    }
    .footer img {
        display: block;
        margin: auto;
        text-align: center;
    }
    .footer-second {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }
    .footer-second h4.footer-subtitle {
        font-size: 13.5px !important;
        letter-spacing: 0.5px !important;
    }
}

@media screen and (width <=280px) {
    .overlay {
        border-radius: 0px 0px 0px 50px !important;
    }
    .overlay a {
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #ffffff;
        display: block;
        text-align: right;
        transition: 250ms;
    }
    .navbar-title .first {
        font-size: 17.5px;
        line-height: 20px;
    }
    .navbar-title .last {
        font-size: 24px;
        line-height: 20px;
    }
    .navbar-actions .navbar-button {
        position: absolute;
        left: 0%;
        right: 8%;
        top: 60%;
        bottom: auto;
        display: block !important;
        margin: auto !important;
        text-align: center !important;
        width: 180px !important;
        max-width: 180px !important;
    }
    .form-title {
        font-size: medium;
    }
    .form-one-step-1 .col-12,
    .form-one-step-2 .col-12,
    .form-two-step-1 .col-12 {
        width: 100% !important;
    }
    .footer-second {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
        vertical-align: super !important;
    }
    h4.pag-count {
        padding-top: 30%;
        font-size: small !important;
    }
    .footer-second .footer-dev-title h4 {
        font-size: 0.46rem !important;
        font-weight: bolder;
    }
}

@media screen and (height <=280px) {
    .header {
        border-radius: 0px 0px 195px 0px !important;
        width: auto !important;
    }
    .nav-link {
        font-size: xx-small !important;
    }
    .navbar-title .first {
        font-size: 25px;
        line-height: 35px;
    }
    .navbar-title .last {
        font-size: 34px;
        line-height: 35px;
    }
    .navbar-actions button {
        position: absolute;
        left: 20%;
        display: block !important;
        margin: auto !important;
        text-align: center !important;
        width: 180px !important;
        min-width: 180px !important;
        font-size: 16px;
    }
}